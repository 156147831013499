import { Injectable } from '@angular/core';
import { CurrentUser } from '../_models/models';
import { environment } from '../../../environments/environment';
import { datadogRum } from '@datadog/browser-rum';

type DatadogServiceAction = 'init' | 'setUser';

@Injectable({
  providedIn: 'root'
})
export class DatadogService {

  private DD_APP_ID = '625d6751-ad13-4a90-aef0-8568bfd8758b';
  private DD_CLIENT_TOKEN = 'pube97b279a8b4e6455d1cade605e79e077';
  private useDatadog = environment.datadog;

  onDatadogAction(action: DatadogServiceAction, payload?: CurrentUser | any): void {
    if (this.useDatadog) {
      switch (action) {
        case 'init':
          this.datadogInit();
          break;
        case 'setUser':
          this.setDatadogUser(payload);
          break;
        default:
          break;
      }
    }
  }

  private setDatadogUser(currentUser?: CurrentUser) {
    if (currentUser?.email) {
      datadogRum.setUser({
        email: currentUser?.email,
        name: `${currentUser?.firstName} ${currentUser?.lastName}`,
      });
    } else {
      datadogRum.clearUser();
    }
  }

  private datadogInit(): void {
    datadogRum.init({
      applicationId: this.DD_APP_ID,
      clientToken: this.DD_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'ctrl',
      env: environment.envName,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: environment.appVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        (url) => url.startsWith(environment.javaApiUrl),
        (url) => url.startsWith(environment.javaInsightsUrl),
        (url) => url.startsWith(environment.exchangeDealsUrl),
      ]
    });
  }

}
