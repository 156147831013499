<ng-container *ngIf="commissionForm" [formGroup]="commissionForm" (keydown.enter)="$event.preventDefault()">
  <nz-row nzGutter="16">
    <nz-col nzMd="8" nzSm="24">
      <nz-form-item>
        <nz-form-label nzRequired>Commission Type</nz-form-label>
        <nz-form-control nz-tooltip [nzTooltipTitle]="getCommissionTooltip('type')">
          <nz-select nzPlaceHolder="Select commission type" formControlName="commissionType" nzShowSearch
                     nzShowArrow="false"
                     [nzSuffixIcon]="selectIcons['commission_type'] ? arrowUp : arrowDown"
                     (nzOpenChange)="onChangeSelectIconState($event, 'commission_type')"
                     style="width: 100%;" id="placement_edit_commission_type">
            <nz-option nzLabel="Revshare" nzValue="REVSHARE"></nz-option>
            <nz-option nzLabel="Fixed-CPM" nzValue="FIXED"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzMd="8" nzSm="24">
      <nz-form-item>
        <nz-form-label nzRequired>Commission</nz-form-label>
        <nz-form-control [nzErrorTip]="getErrorCommission(commissionForm.controls.commission)"
                         nz-tooltip [nzTooltipTitle]="getCommissionTooltip('value')">
          <nz-input-group [nzAddOnAfter]="commissionForm?.get('commissionType').value === 'REVSHARE' ? '%' : '$'">
            <nz-input-number formControlName="commission" [nzStep]="0.001" [nzMin]="0" [nzMax]="100"
                             style="width: 100%" [nzId]="'placement_edit_commission'">
            </nz-input-number>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzMd="8" nzSm="24" style="text-align: center">
      <nz-form-item>
        <nz-form-label></nz-form-label>
        <nz-form-control>
          <label
            nz-checkbox
            formControlName="overridePubTsCommission"
            id="commission-override">
            Override traffic source commission
          </label>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

  </nz-row>
</ng-container>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>
