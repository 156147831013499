import { AppFilter, AppTable, AppTableColumn, ModalButton } from '../../_models/models';
export const today: Date = new Date();
export const initialDate: Date = new Date(today.getFullYear() - 1, 0, 1);
export const GOOGLE_ACCOUNTID = 12221;
export const billingHistoryFiltersConfig: AppFilter[] = [
  new AppFilter('billingHistoryDatesRange', 'DATES_RANGE', 'timestamp', 'Date Range (EST)', '',
    'default', true, false, undefined, [],
    [initialDate, today], undefined, undefined, undefined, true,
    [initialDate, today], undefined, undefined,
    undefined, undefined, true)
];

export const billingHistoryConfig: AppTable = new AppTable('billingInfoTable', 0, 12, 'billPaymentDate', 'desc', true, true);


export const billingHistoryTableButtons = [
  {id: 'generateBillingReportButton', type: 'SECONDARY-BUTTON', title: 'Retrieve History', hasLoadingSpinner: false},
];

export const billingHistoryTableColumns: AppTableColumn[] = [
  {id: 'billingInfoDate', prop: 'billPaymentDate', displayName: 'Payment Date', type: 'date-full-month-day-year', isSortEnabled: true},
  {id: 'billingInfoPayment', prop: 'totalAmount', displayName: 'Payment Amount', type: 'currency',  isSortEnabled: true},
  {
    id: 'actions', prop: 'actions', displayName: 'Details', type: 'actions', isSortEnabled: false,
    actions: [
      {id: 'billPaymentDetails', name: 'moreDetails', auth: 'canRead', displayName: 'Payment Details'},
    ]
  },
];

export const modalButtons: ModalButton[] = [
  {type: 'APPROVE', text: 'Ok'}
];

export const authPermissions = {
  canRead: true,
  canCreate: false,
  canUpdate: false,
  canDelete: false,
};
