import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AppTable,
  AppTableColumn,
  AuthPermissions,
  JapiQuery,
  CtrlUser,
  ResponseFromServer,
  SentinelResponse,
  SentinelRule,
  SentinelSubscription,
  SentinelSubscriptionResponse,
  ThinPublisher,
  ClusivityStatus,
  UserType,
} from '../../_models/models';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { FormHelper } from '../../_common/form.helper';
import {
  catchError, filter, finalize,
  map,
  switchMap, switchMapTo,
  tap,
} from 'rxjs/operators';
import {
  alertsTable,
  AlertsTableRow, AlertWithStatus,
  authPermissions,
  externalAlertsTableColumns,
  internalAlertsTableColumns,
} from '../../_services/alerts.config';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '../../_services/alerts.service';
import { cloneDeep } from 'lodash';
import { SupplyPublisherService } from '../../../features/supply/publishers/_services/publisher.service';

@Component({
  selector: 'app-alerts-management',
  templateUrl: './alerts-management.component.html',
  styleUrls: ['./alerts-management.component.less']
})
export class AlertsManagementComponent implements OnInit, OnDestroy {


  @Input()
    user: CtrlUser;
  @Output() alertsSubmitResult: EventEmitter<{ status: boolean; message: string }> = new EventEmitter();


  alertsTable: AppTable = alertsTable;
  userType: UserType;
  isLoading = false;
  currentQuery: JapiQuery;
  alertsTableData$: Observable<AlertsTableRow[]> = of([]);
  alertsToggleStatus: Map<number, AlertWithStatus> = new Map();
  alertsTableColumns: AppTableColumn[];
  authUser: AuthPermissions = authPermissions;

  private unsubscribe$ = new Subject<void>();


  constructor(private fh: FormHelper,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertsService: AlertsService,
    private publishersService: SupplyPublisherService) {
  }




  ngOnInit(): void {
    if (this.user) {
      this.currentQuery = cloneDeep(this.alertsService.DEFAULT_API_QUERY);
      this.userType = this.user.type === 'INTERNAL' ? 'INTERNAL' : 'EXTERNAL';
      this.isLoading = true;
      this.alertsTableColumns = this.initAlertsTableColumnsByUserType(this.userType);
      this.alertsTableData$ = this.alertsService.getSentinelKongApiKey()
        .pipe(
          filter(apiKey => apiKey.length > 0),
          switchMapTo(this.initAlertsTableDataByUserType(this.userType)
            .pipe(
              finalize(() => this.isLoading = false))
          ),
        );
    }
  }

  initSentinelSubscriptions(): Observable<SentinelResponse<SentinelSubscriptionResponse[]>> {
    return this.alertsService.getActiveSubscriptions(this.user.id)
      .pipe(
        catchError(() => this.alertsService.handleErrors('Failed to load publishers subscriptions')),
      );
  }

  initInternalUsersTable(): Observable<AlertsTableRow[]> {
    return this.initSentinelSubscriptions().pipe(
      catchError(() => this.alertsService.handleErrors('An error occurred while loading alerts')),
      switchMap((subscriptionsResponse) => this.createAlertsTableRows(subscriptionsResponse.data)),
    );
  }

  initExternalUsersTable() {
    return this.initSentinelRules().pipe(
      switchMap((rulesResponse) => this.createAlertsTableRowsWithRules(rulesResponse.data)),
      switchMap(rows => this.initAlertsToggleStatusMap(rows)
        .pipe(
          switchMapTo(this.initSentinelSubscriptions()),
          switchMap(subscriptionsResponse => this.toggleCheckboxesBySubscriptions(rows, subscriptionsResponse.data))
        )));
  }
  initSentinelRules(): Observable<SentinelResponse<SentinelRule[]>> {
    return this.alertsService.getSentinelRules(this.userType);
  }


  handleAlertsToggle(): Observable<SentinelResponse<SentinelSubscriptionResponse[]>[]> {
    const selectedAlerts = Array.from(this.alertsToggleStatus.values());
    const alertsIdsToDisable: number[] = this.filterSelectedAlertsByToggle(selectedAlerts, false);
    const alertsIdsToEnable: number[] = this.filterSelectedAlertsByToggle(selectedAlerts, true);
    let alertsToDisable$: Observable<SentinelResponse<SentinelSubscriptionResponse[]>> = of({data: []});
    let alertsToEnable$: Observable<SentinelResponse<SentinelSubscriptionResponse[]>> = of({data: []});


    if ((alertsIdsToDisable.length === 0 && alertsIdsToEnable.length === 0)) {
      return of([{data: []}]);
    }

    if (alertsIdsToEnable.length > 0) {
      const alertsToEnableQuery: SentinelSubscription[] = alertsIdsToEnable
        .map(alert => this.alertsService.buildSentinelQuery(alert, this.user));
      alertsToEnable$ = this.alertsService.subscribeManyAlerts(alertsToEnableQuery)
        .pipe(catchError((err) => this.onFailedAlertsChange(err, 'Could not subscribe')));
    }

    if (alertsIdsToDisable.length > 0) {
      const subscriptionIdsToDisable: number[] = this.alertsService.getSubscriptionsIdsByRuleIds(alertsIdsToDisable);
      alertsToDisable$ = this.alertsService.unsubscribeManyAlerts(subscriptionIdsToDisable)
        .pipe(catchError((err) => this.onFailedAlertsChange(err, 'Could not unsubscribe')));
    }
    return this.activateAlerts(alertsToEnable$, alertsToDisable$);
  }



  onAlertRowAction(event: { row: AlertsTableRow; action: string }): void {
    switch (event.action) {
      case 'alertEditAction':
        this.router.navigate([`alerts/${event.row.ruleId}`],
          {relativeTo: this.activatedRoute});
        return;
      case 'alertToggleAction' : {
        const alertId: number = event.row.ruleId;
        const alertToUpdate: AlertWithStatus = this.alertsToggleStatus.get(alertId);
        alertToUpdate.status = this.switchStatus(alertToUpdate.status);
        this.alertsToggleStatus.set(alertId, alertToUpdate);
        return;
      }
    }

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initAlertsTableDataByUserType(userType: UserType): Observable<AlertsTableRow[]> {
    if (userType === 'INTERNAL') {
      return this.initInternalUsersTable();
    }
    return this.initExternalUsersTable();
  }

  private initAlertsTableColumnsByUserType(userType: UserType): AppTableColumn[] {
    if (userType === 'INTERNAL') {
      return internalAlertsTableColumns;
    }
    return externalAlertsTableColumns;
  }

  private filterAlertsByStatus(alerts: AlertWithStatus[], status: ClusivityStatus): AlertWithStatus[] {
    return alerts.filter(alert => alert.status === status);
  }

  private filterSelectedAlertsByToggle(selectedAlerts: AlertWithStatus[], toggled: boolean): number[] {
    const alertsFilteredByStatus = toggled ?
      this.filterAlertsByStatus(selectedAlerts, 'ACTIVE') : this.filterAlertsByStatus(selectedAlerts, 'INACTIVE');

    return alertsFilteredByStatus
      .filter(alert => {
        const existingAlert: SentinelSubscriptionResponse = this.alertsService.activeSubscriptions$.value
          .find(sub => sub.rules.id === alert.ruleId);
        if (!existingAlert && toggled || existingAlert && !toggled) {
          return alert;
        }
      }).map(alert => alert.ruleId);
  }


  private initAlertsToggleStatusMap(rows: AlertsTableRow[]): Observable<AlertsTableRow[]> {
    rows.forEach(row => this.alertsToggleStatus.set(row.ruleId, {
      ruleId : row.ruleId,
      status: row.status
    }));
    return of(rows);
  }

  private toggleCheckboxesBySubscriptions(rows: AlertsTableRow[], subscriptions: SentinelSubscriptionResponse[]):
  Observable<AlertsTableRow[]> {
    if (subscriptions.length > 0) {
      subscriptions.forEach(subscription => {
        const alert: AlertWithStatus = this.alertsToggleStatus.get(subscription.rules.id);
        this.alertsToggleStatus.set(alert.ruleId, {...alert, status: 'ACTIVE'});
        const alertRowToUpdate: AlertsTableRow = rows.find(row => row.ruleId === subscription.rules.id);
        alertRowToUpdate.status = 'ACTIVE';
      });
    }
    return of(rows);
  }


  private onFailedAlertsChange(err: Error, message: string) {
    console.error(err);
    this.alertsSubmitResult.emit({status: false, message: message});
    return EMPTY;
  }

  private activateAlerts(enable$: Observable<SentinelResponse<SentinelSubscriptionResponse[]>>,
    disable$: Observable<SentinelResponse<SentinelSubscriptionResponse[]>>):
    Observable<SentinelResponse<SentinelSubscriptionResponse[]>[]> {
    return forkJoin([enable$, disable$])
      .pipe(tap(([enable, disable]) => {
        if (enable.data?.length > 0) {
          this.alertsService.activeSubscriptions$.next([...this.alertsService.activeSubscriptions$.value, ...enable.data]);
        }

        if (disable.data?.length > 0) {
          const deletedIds: number[] = disable.data.map(subscription => subscription.id);
          this.alertsService.activeSubscriptions$.next( this.alertsService.activeSubscriptions$.value
            .filter(subscription => !deletedIds.includes(subscription.id)));
        }

        this.alertsSubmitResult.emit({status: true, message: 'Updated subscriptions'});
      }));
  }

  private switchStatus (switchToggleStatus: ClusivityStatus): ClusivityStatus {
    return switchToggleStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
  }

  private getSubscribedPublishersNames(subscriptions: SentinelSubscriptionResponse[]): Observable<ResponseFromServer<ThinPublisher[]>> {
    const query: JapiQuery = this.alertsService.buildGetPublishersNamesQuery(subscriptions);

    return this.publishersService.getFilteredPublishers(query);
  }

  private createAlertsTableRows(subscriptions: SentinelSubscriptionResponse[]): Observable<AlertsTableRow[]> {
    if (subscriptions.length > 0) {
      return this.getSubscribedPublishersNames(subscriptions)
        .pipe(
          switchMap((publishersNames) =>
            this.createAlertsTableRowsWithPublishers(publishersNames.data, subscriptions)),
        );
    }
    return this.initSentinelRules()
      .pipe(
        switchMap(rulesResponse => this.createAlertsTableRowsWithRules(rulesResponse.data))
      );
  }
  private createAlertsTableRowsWithPublishers(publishers: ThinPublisher[], subscriptions: SentinelSubscriptionResponse[]):
  Observable<AlertsTableRow[]> {
    const publishersMap: Map<string | number, string> = this.buildPublishersNameMap(publishers);

    return this.initSentinelRules()
      .pipe(
        switchMap(rulesResponse => this.createAlertsTableRowsWithRules(rulesResponse.data)),
        map(rows => this.mapPublisherSubscriptionsToRows(rows, subscriptions, publishersMap))
      );
  }
  private createAlertsTableRowsWithRules (rules: SentinelRule[]): Observable<AlertsTableRow[]> {
    return of(rules
      .sort((ruleA, ruleB) => ruleA.name < ruleB.name ? -1 : 1)
      .map(fetchedRule => ({
        description: fetchedRule.description,
        status: 'INACTIVE',
        ruleId: fetchedRule.id,
        ...(this.userType === 'INTERNAL' && { publishers: [] }),
        notificationName: fetchedRule.notificationName,
        name: fetchedRule.name
      })
      ));
  }

  private buildPublishersNameMap(publishers: ThinPublisher[]): Map<string | number, string> {
    const publishersMap: Map<string | number, string> = new Map();

    publishers.forEach(publisher => publishersMap.set(publisher.publisherId, publisher.publisherName));

    return publishersMap;
  }

  private mapPublisherSubscriptionsToRows(rows: AlertsTableRow[],
    subscriptions: SentinelSubscriptionResponse[], publishersNamesMap: Map<string | number, string>): AlertsTableRow [] {
    subscriptions.forEach(subscription => {
      const alertRowToUpdate = rows
        .findIndex(alertRow => alertRow.notificationName === subscription.rules.notificationName);

      rows[alertRowToUpdate].publishers = subscription.templateValue.values.publishers.map(pub => ({
        publisherId: pub,
        publisherName: publishersNamesMap.get(pub)
      }));
    });

    return rows;
  }
}
