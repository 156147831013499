import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  CtrlUser,
  ResponseFromServer,
  ThinPublisher
} from '../../../../_models/models';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UntypedFormGroup } from '@angular/forms';
import { AlertsService } from '../../../../_services/alerts.service';
import { SupplyPublisherService } from '../../../../../features/supply/publishers/_services/publisher.service';

@Component({
  selector: 'app-add-publisher-to-alert',
  templateUrl: './add-publisher-to-alert.component.html',
  styleUrls: ['./add-publisher-to-alert.component.less']
})
export class AddPublisherToAlertComponent implements OnInit, OnDestroy {
  @Input()
    addPublisherForm: UntypedFormGroup;
  @Input()
    user: CtrlUser;
  @Output()
    informOnSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  publisherSearch$: Subject<string> = new Subject();

  isLoading = false;
  publishers: ThinPublisher[] = [];
  selectedPublisher: ThinPublisher;

  private unsubscribe$ = new Subject<void>();


  constructor(private publishersService: SupplyPublisherService,
    private readonly alertsService: AlertsService) { }



  ngOnInit(): void {
    this.initPublishers();
    this.initSelectListener();
    this.initSearchListener();
  }

  onPublisherSelect(selectedPublisher): void {
    this.selectedPublisher = this.publishers.find(pub => pub.publisherId === selectedPublisher);
  }

  initPublishers() {
    this.publishersService.getFilteredPublishers(this.alertsService.DEFAULT_API_QUERY)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => this.alertsService.handleErrors('Failed to load publishers')))
      .subscribe(res => this.publishers = res.data);
  }

  initSearchListener(): void {
    this.publisherSearch$.pipe(
      debounceTime(700),
      distinctUntilChanged(),
      filter(val => val === '' || val.length >= 2),
      switchMap(val => this.onSearchPublishers(val)),
      catchError(() => {
        this.isLoading = false;
        return EMPTY;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe((res: ResponseFromServer<ThinPublisher[]>) => {
      this.publishers = res.data;
      this.isLoading = false;
    });
  }

  onSearchPublishers(searchValue: string): Observable<ResponseFromServer<ThinPublisher[]>> {
    this.isLoading = true;
    const query = cloneDeep(this.alertsService.DEFAULT_API_QUERY);
    if (searchValue) {
      const isString = isNaN(+searchValue);
      query.filter.filters.push({
        fieldName: isString ? 'publisherName' : 'publisherId',
        operation: isString ? 'LIKE' : 'EQUALS',
        value: searchValue
      });
    }
    return this.publishersService.getFilteredPublishers(query);
  }

  onSubmit(): void {
    this.informOnSubmit.emit(this.addPublisherForm.valid);
  }

  onSearch(value: string): void {
    this.isLoading = true;
    this.publisherSearch$.next(value);
  }

  initSelectListener(): void {
    this.addPublisherForm.get('publisherId').valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (!value) {
          this.publisherSearch$.next('');
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
