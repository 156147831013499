import { Injectable } from '@angular/core';

import _ from 'lodash';

import { Store } from '@ngrx/store';
import * as fromPlacements from '../_store/placements.reducer';
import * as PlacementsActions from '../_store/placements.actions';

import { ApiService } from '../../../shared/_services/api.service';
import { SharedService } from '../../../shared/_services/shared.service';
import { JapiQuery, Placement, PlacementStatus } from '../../../shared/_models/models';

@Injectable({providedIn: 'root'})

export class PlacementsService {

  constructor(
    private store: Store<fromPlacements.PlacementsState>,
    private api: ApiService,
    private sharedService: SharedService
  ) {
  }

  getPlacementById(placementId: number): void {
    const placementQuery = {
      filter: {id: placementId},
      include: ['floors', 'commissions']
    };
    this.store.dispatch(new PlacementsActions.FetchPlacementByIdRequestAction());
    this.api.getPlacements(placementQuery)
      .subscribe(res => {
        if (res.data.length > 0) {
          this.store.dispatch(new PlacementsActions.FetchPlacementByIdSuccessAction({currentPlacement: res.data[0]}));
        } else {
          this.sharedService.onHttpCompletionResolveRelatedRoute(false, 'Failure', 'Placement not found',
            3, '');
        }
      },
      (err) => {
        this.store.dispatch(new PlacementsActions.FetchPlacementByIdFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Placement fetch failed',
          '', '');
      });
  }

  getPlacements(query: JapiQuery = null) {
    this.store.dispatch(new PlacementsActions.FetchPlacementsRequestAction());
    this.api.getPlacements(query)
      .subscribe((res) => {
        this.store.dispatch(new PlacementsActions.FetchPlacementsSuccessAction({placements: res}));
      },
      (err) => {
        this.store.dispatch(new PlacementsActions.FetchPlacementsFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Placements fetch failed',
          '', '');
      });
  }

  getIabCategories() {
    this.store.dispatch(new PlacementsActions.FetchIabCategoriesRequestAction());
    this.api.getIabCategories()
      .subscribe((res: any) => {
        if (res.data && res.data.length) {
          res.data = _.orderBy(res.data, ['title']);
          res.data.forEach(parent => {
            parent.children = _.orderBy(parent.children, ['title']);
          });
        }
        this.store.dispatch(new PlacementsActions.FetchIabCategoriesSuccessAction({iabCategories: res}));
      },
      (err: any) => {
        this.store.dispatch(new PlacementsActions.FetchIabCategoriesFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Iab categories fetch failed',
          '', '');
      });
  }

  getContentGenres(): void {
    this.store.dispatch(new PlacementsActions.FetchContentGenresRequestAction());
    this.api.getContentGenres()
      .subscribe(res => {
        this.store.dispatch(new PlacementsActions.FetchContentGenreSuccessAction({contentGenres: res.data}));
      },
      (err: any) => {
        this.store.dispatch(new PlacementsActions.FetchContentGenreFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Content genres fetch failed',
          '', '');
      });
  }

  getIabBannerAdTypes() {
    this.store.dispatch(new PlacementsActions.FetchIabBannerAdTypesRequestAction());
    this.api.getIabBannerAdTypes()
      .subscribe(res => {
        this.store.dispatch(new PlacementsActions.FetchIabBannerAdTypesSuccessAction({iabBannerAdTypes: res}));
      },
      err => {
        this.store.dispatch(new PlacementsActions.FetchIabBannerAdTypesFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Iab banner ad types fetch failed',
          '', '');
      });
  }

  getIabCreativeAttributes() {
    this.store.dispatch(new PlacementsActions.FetchIabCreativeAttributesRequestAction());
    this.api.getIabCreativeAttributes()
      .subscribe(res => {
        this.store.dispatch(new PlacementsActions.FetchIabCreativeAttributesSuccessAction({iabCreativeAttributes: res}));
      },
      err => {
        this.store.dispatch(new PlacementsActions.FetchIabCreativeAttributesFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Iab creative attributes fetch failed',
          '', '');
      });
  }

  getDspAccounts() {
    this.store.dispatch(new PlacementsActions.FetchDspAccountsRequestAction());
    this.api.getDspAccounts({})
      .subscribe((res: any) => {
        this.store.dispatch(new PlacementsActions.FetchDspAccountsSuccessAction({dspAccounts: res}));
      },
      (err: any) => {
        this.store.dispatch(new PlacementsActions.FetchDspAccountsFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'DSP Accounts fetch failed',
          '', '');
      });
  }

  getPublisherTrafficSourceById(pubTsId: number, publisherId: number) {
    const query = {
      filter: {
        filters: [
          {fieldName: 'pubTsId', operation: 'EQUALS', value: pubTsId}
        ],
      },
      include: ['floors', 'commissions']
    };
    this.store.dispatch(new PlacementsActions.FetchPubTsByIdRequestAction());
    this.api.getPublisherTrafficSourceById(query, publisherId)
      .subscribe((res: any) => {
        this.store.dispatch(new PlacementsActions.FetchPubTsByIdSuccessAction({pubTs: res}));
      },
      (err: any) => {
        this.store.dispatch(new PlacementsActions.FetchPubTsByIdFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Publisher traffic source fetch failed',
          '', '');
      });
  }


  updatePlacement(placement: FormData, placementId: number): void {
    this.store.dispatch(new PlacementsActions.EditPlacementRequestAction());
    this.api.updatePlacement(placement, placementId)
      .subscribe(res => {
        const data = res.data;
        this.store.dispatch(new PlacementsActions.EditPlacementSuccessAction({currentPlacement: data}));
        this.sharedService.onHttpCompletionResolve(true, 'Success', 'Placement updated successfully',
          '', '');
        this.handleDomainsDuplications(data);
      },
      err => {
        this.store.dispatch(new PlacementsActions.EditPlacementFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Placement update failed',
          '', '');
      });
  }

  handleDomainsDuplications(data: Placement) {
    const numberOfUnsavedDomains = data?.numberOfUnsavedDomains;
    const numberOfSavedDomains = data?.numberOfSavedDomains;
    if (numberOfUnsavedDomains > 0) {
      if (numberOfSavedDomains === 0) {
        this.sharedService.showNotification('success', 'Success',
          'Domains operation completed, though all domains to add detected as duplicated/invalid format');
      } else {
        this.sharedService.showNotification('success', 'Success',
          `Domains operation completed, created ${numberOfSavedDomains} domains, ${numberOfUnsavedDomains} detected as duplicated/invalid format`);
      }
    }
  }

  updatePlacementStatus(placementId: number, placementStatus: PlacementStatus): void {
    this.store.dispatch(new PlacementsActions.EditPlacementRequestAction());
    this.api.updatePlacementStatus(placementId, placementStatus)
      .subscribe(res => {
        this.store.dispatch(new PlacementsActions.EditPlacementSuccessAction({currentPlacement: res.data}));
        this.sharedService.onHttpCompletionResolve(true, 'Success', 'Placement updated successfully',
          '', '');
      },
      err => {
        this.store.dispatch(new PlacementsActions.EditPlacementFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Placement update failed',
          '', '');
      });
  }

  createPlacement(placement: FormData): void {
    this.store.dispatch(new PlacementsActions.CreatePlacementRequestAction());
    this.api.createPlacement(placement)
      .subscribe((res: any) => {
        const data = res.data;
        this.store.dispatch(new PlacementsActions.CreatePlacementSuccessAction({currentPlacement: data}));
        this.sharedService.onHttpCompletionResolve(true, 'Success', 'Placement created successfully',
          '', '');
        this.handleDomainsDuplications(data);
      },
      (err) => {
        this.store.dispatch(new PlacementsActions.CreatePlacementFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Placement creation failed',
          '', '');
      });
  }


  checkDomainBundleDuplicateStatus(placementId: number) {
    this.api.checkDomainBundleDuplicateStatus(placementId)
      .subscribe((res) => {
        this.store.dispatch(new PlacementsActions.SetDomainBundlesDuplicateStatus({isDomainBundlesDuplicateFinished: !!res}));
      },
      () => {
        this.store.dispatch(new PlacementsActions.SetDomainBundlesDuplicateStatus({isDomainBundlesDuplicateFinished: false}));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Unknown domain bundles duplicate status',
          '', '');
      });
  }

  duplicateDomainBundlesByPlacementId(origPlacementId: number, newPlacementId: number) {
    this.api.duplicateDomainBundles(origPlacementId, newPlacementId)
      .subscribe((res: any) => {
        if (res && res.status === 'FINISHED') {
          this.store.dispatch(new PlacementsActions.SetDomainBundlesDuplicateStatus({isDomainBundlesDuplicateFinished: !!res}));
          this.sharedService.onHttpCompletionResolve(true, 'Success', 'Domain bundles duplicated',
            '', '');
        }
      },
      () => {
        this.store.dispatch(new PlacementsActions.SetDomainBundlesDuplicateStatus({isDomainBundlesDuplicateFinished: false}));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Domain bundles duplication failed',
          '', '');
      });
  }
}
