import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl, AbstractControl, Validators } from '@angular/forms';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { each } from 'lodash';

@Injectable()

export class FormHelper {

  public emailValidator = [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')];

  public static isRequired(form: UntypedFormGroup, prop: string) {
    if (!form) {
      return false;
    }
    const control = form.get(prop);
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  public markFormArrayDirty(formArray: UntypedFormArray) {
    formArray.markAsDirty();
    formArray.controls.forEach((control) => {
      control.markAsDirty();
    });
  }

  public getDirtyValues(fg: UntypedFormGroup): any {
    let dirtyValues = {};  // initialize empty object
    if (fg instanceof UntypedFormArray) {
      dirtyValues = [];
    }
    Object.keys(fg.controls).forEach((c) => {
      const currentControl = <any>fg.controls[c];
      if (currentControl.dirty) {
        if (currentControl.controls) { // check for nested controlGroups
          dirtyValues[c] = this.getDirtyValues(currentControl);  // recursion for nested controlGroups
        } else {
          dirtyValues[c] = currentControl.value;  // simple control
        }
      }
    });
    return dirtyValues;
  }

  public formArrayUpdate(formArray: UntypedFormArray, values: Array<string>): void {
    // Remove all items from array
    for (let i = formArray.controls.length - 1; i >= 0; i--) {
      formArray.removeAt(i);
    }

    // Add selected items to array
    values.forEach((value: string) => {
      formArray.push(new UntypedFormControl(value));
    });
  }

  public deepTreeGetCheckedKeys(node: NzTreeNode[]): Array<string> {
    // Go through tree nodes and get all checked nodes
    let nodeKeyArray: Array<string> = [];
    for (let i = 0; i <= node.length - 1; i++) {
      if (node[i].getChildren().length === 0) {
        if (node[i].isChecked) {
          nodeKeyArray.push(node[i].key);
        }
      } else {
        nodeKeyArray = nodeKeyArray.concat(this.deepTreeGetCheckedKeys(node[i].getChildren()));
      }
    }
    return nodeKeyArray;
  }

  public deepTreeGetCheckedIdsAndKeys(node: NzTreeNode[]): Array<{id: string|number; key: string}> {
    // Go through tree nodes and get all checked nodes
    let nodeIdAndKeyArray: Array<{id: string|number; key: string}> = [];
    for (let i = 0; i <= node.length - 1; i++) {
      if (node[i].getChildren().length === 0) {
        if (node[i].isChecked) {
          nodeIdAndKeyArray.push({id: node[i].origin.id, key: node[i].key});
        }
      } else {
        nodeIdAndKeyArray = nodeIdAndKeyArray.concat(this.deepTreeGetCheckedIdsAndKeys(node[i].getChildren()));
      }
    }
    return nodeIdAndKeyArray;
  }

  public inArray(array: Array<string>, value: any): boolean {
    if (!array || !value) {
      return false;
    }
    return array.indexOf(value) !== -1;
  }

  public anyInArray(array: Array<string>, value: Array<string>): boolean {
    if (!array || !value) {
      return false;
    }
    let contains = false;
    for (let i = 0; i < value.length; i++) {
      if (array.indexOf(value[i]) !== -1) {
        contains = true;
      }
    }
    return contains;
  }

  public getAvatar(file: any, avatar: any): void {
    if (!file) {
      avatar = undefined;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      avatar = reader.result;
    };
  }

  public encodeFiltersToURL(data) {
    if (typeof data === 'string') {
      return `filter=${encodeURIComponent(data)}`;
    } else {
      return Object
        .keys(data)
        .map(value => `filter[${value}]=${encodeURIComponent(data[value])}`)
        .join('&');
    }
  }

  public encodePageToURL(data) {
    return Object
      .keys(data)
      .map(value => `page[${value}]=${encodeURIComponent(data[value])}`)
      .join('&');
  }

  noWhitespaceValidator(control: UntypedFormControl) {
    if (control.value === null) {
      return {'whitespace': true};
    }
    const isWhitespace = (control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    if (formGroup.controls) {
      Object.keys(formGroup.controls).map((x) => formGroup.controls[x]).forEach((control: any) => {
        control.markAsTouched();
        if (control.controls) {
          each(control.controls, (c) => {
            this.markFormGroupTouched(c);
          });
        }
      });
    } else {
      formGroup.markAsTouched();
    }
  }
  markFormGroupDirtyAndUpdateValidity(formGroup: UntypedFormGroup | UntypedFormArray, excludedFormControls?: string[]) {
    if (formGroup?.controls) {
      Object.keys(formGroup.controls).forEach((key) => {
        if (!excludedFormControls || !excludedFormControls.includes(key)) {
          const control = formGroup.controls[key];
          control.markAsDirty();
          control.updateValueAndValidity();
          if (control.controls) {
            this.markFormGroupDirtyAndUpdateValidity(control, excludedFormControls);
          }
        }
      });
    } else {
      formGroup?.markAsDirty();
      formGroup?.updateValueAndValidity();
    }
  }

  markFormGroupDirtyAndUpdateValidityWithExcludedFormControl(formGroup: UntypedFormGroup | UntypedFormArray, excludedFormControl: String) {
    if (formGroup.controls) {
      Object.keys(formGroup.controls).filter(fc => fc !== excludedFormControl).map((x) => {
          return formGroup.controls[x];
      }).forEach((control: any) => {
        control.markAsDirty();
        control.updateValueAndValidity();
        if (control.controls) {
          each(control.controls, (c) => {
            this.markFormGroupDirtyAndUpdateValidity(c);
          });
        }
      });
    } else {
      formGroup.markAsDirty();
      formGroup.updateValueAndValidity();
    }
  }

  mapFormGroupToValidityObj(validityMap, formGroup: UntypedFormGroup | UntypedFormArray) {
    const obj = {};
    if (formGroup.controls) {
      Object.keys(formGroup.controls).forEach(key => {
        obj[key] = {
          _: formGroup.controls[key].status,
        };
        if (formGroup.controls[key].controls) {
          obj[key].controls = this.mapFormGroupToValidityObj(validityMap[key], formGroup.controls[key].controls);
        }
      });
    }
    return obj;
  }

}


